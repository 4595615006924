import {
  SELECT_CHAT,
  APP_LOADING_FINISHED,
  STORE_GROUP_LIST,
  STORE_HISTORY,
  FETCH_HISTORY,
  NEW_MESSAGE,
  INCREMENT_UNREAD_COUNT,
  STORE_MESSAGE_SCROLL_ID,
  CLEAR_MESSAGE_SCROLL_ID,
  STORE_HC_PATIENT_IDS,
  CLEAR_UNREAD_MESSAGE,
} from './constants';

const initialState = {
  groupsList: [],
  patientIds: [],
  totalCount: 0,
  page: {
    next: '',
    prev: '',
  },
  selectedChat: null,
  history: {},
  loading: true,
  messageScrollId: null,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_GROUP_LIST:
      return { ...state, ...action.payload };
    case STORE_HC_PATIENT_IDS:
      return { ...state, patientIds: action.payload };
    case SELECT_CHAT:
      return { ...state, selectedChat: action.payload };
    case STORE_HISTORY:
      return {
        ...state,
        messageScrollId: `message-${action?.payload?.history?.messages?.[0]?.timetoken}`,
        history: {
          ...state.history,
          [action?.payload?.groupId]: action?.payload?.history,
        },
      };
    case FETCH_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          [action?.payload?.channelId]: {
            isMore: action?.payload?.history?.isMore,
            messages: [
              ...(action?.payload?.history?.messages ?? []),
              ...(state.history?.[action?.payload?.channelId]?.messages || []),
            ],
          },
        },
      };

    case NEW_MESSAGE: {
      const updatedGroupsList = state.groupsList.map((group) => {
        if (group.channel.id === action?.payload?.groupId) {
          return {
            ...group,
            updatedAt: new Date(),
          };
        }
        return group;
      });

      // Find the updated group with the new message and move it to the top of the list
      const sortedGroupsList = updatedGroupsList.sort((a, b) => {
        if (a?.channel?.id === action?.payload?.groupId) return -1; // Move the group with new message to top
        if (b?.channel?.id === action?.payload?.groupId) return 1;

        const isAUnread = a?.channel_meta_info?.unread_count > 0;
        const isBUnread = b?.channel_meta_info?.unread_count > 0;

        if (isAUnread && !isBUnread) return -1;
        if (!isAUnread && isBUnread) return 1;

        return (
          new Date(b?.updatedAt).getTime() - new Date(a?.updatedAt).getTime()
        );
      });

      return {
        ...state,
        history: {
          ...state.history,
          [action?.payload?.groupId]: {
            messages: [
              ...(state?.history?.[action?.payload?.groupId]?.messages || []),
              action?.payload?.message,
            ],
          },
        },
        groupsList: sortedGroupsList,
      };
    }

    case INCREMENT_UNREAD_COUNT:
      const { channelId } = action.payload;

      const updatedGroupsListIUC = state.groupsList.map((group) => {
        if (group?.channel?.id === channelId) {
          return {
            ...group,
            channel_meta_info: {
              ...group.channel_meta_info,
              unread_count: (group.channel_meta_info.unread_count || 0) + 1,
            },
          };
        }
        return group;
      });

      return {
        ...state,
        groupsList: updatedGroupsListIUC,
      };

    case CLEAR_UNREAD_MESSAGE:
      const updatedGroupsList = state.groupsList.map((group) => {
        if (group?.channel?.id === action?.payload?.channelId) {
          return {
            ...group,
            channel_meta_info: {
              ...group.channel_meta_info,
              unread_count: 0,
            },
          };
        }
        return group;
      });

      return {
        ...state,
        groupsList: updatedGroupsList,
      };
    case STORE_MESSAGE_SCROLL_ID:
      return { ...state, messageScrollId: `message-${action.payload}` };
    case CLEAR_MESSAGE_SCROLL_ID:
      return { ...state, messageScrollId: null };
    case APP_LOADING_FINISHED:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default messageReducer;
