import {
  STORE_GROUP_LIST,
  SELECT_CHAT,
  STORE_HISTORY,
  FETCH_HISTORY,
  APP_LOADING_FINISHED,
  NEW_MESSAGE,
  STORE_MESSAGE_SCROLL_ID,
  CLEAR_MESSAGE_SCROLL_ID,
  STORE_HC_PATIENT_IDS,
  INCREMENT_UNREAD_COUNT,
  CLEAR_UNREAD_MESSAGE,
} from './constants';

export const storeGroupList = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STORE_GROUP_LIST, payload });
    } catch (error) {
      console.log('[STORE GROUPS ERROR]: ', error);
    }
  };
};

export const storeHCPatientIds = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STORE_HC_PATIENT_IDS, payload });
    } catch (error) {
      console.log('[STORE HC PATIENT IDS ERROR]: ', error);
    }
  };
};

export const selectChatAction = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SELECT_CHAT, payload });
    } catch (error) {
      console.log('[SELECT CHAT ACTION ERROR]: ', error);
    }
  };
};

export const storeHistory = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STORE_HISTORY, payload });
    } catch (error) {
      console.log('[STORE HISTORY ERROR]: ', error);
    }
  };
};

export const fetchHistory = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_HISTORY, payload });
    } catch (error) {
      console.log('[FETCH HISTORY ERROR]: ', error);
    }
  };
};

export const updateGroupList = (channelId, message) => {
  return async (dispatch) => {
    try {
      dispatch({ type: NEW_MESSAGE, payload: { groupId: channelId, message } });
    } catch (error) {
      console.log('[UPDATE GROUP LIST ERROR]: ', error);
    }
  };
};

export const incrementUnreadCount = (channelId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INCREMENT_UNREAD_COUNT, payload: { channelId } });
    } catch (error) {
      console.log('[INCREMENT UNREAD COUNT ERROR]: ', error);
    }
  };
};

export const clearUnreadMessageCount = (channelId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CLEAR_UNREAD_MESSAGE, payload: { channelId } });
    } catch (error) {
      console.log('[CLEAR UNREAD COUNT ERROR]: ', error);
    }
  };
};

export const storeMessageId = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STORE_MESSAGE_SCROLL_ID, payload });
    } catch (error) {
      console.log('[APP LOADING FINISHED ACTION ERROR]: ', error);
    }
  };
};

export const clearMessageId = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CLEAR_MESSAGE_SCROLL_ID });
    } catch (error) {
      console.log('[APP LOADING FINISHED ACTION ERROR]: ', error);
    }
  };
};

export const appLoadingFinished = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: APP_LOADING_FINISHED });
    } catch (error) {
      console.log('[APP LOADING FINISHED ACTION ERROR]: ', error);
    }
  };
};
