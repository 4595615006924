import React, { useEffect } from 'react';

import UserList from '../components/chat_sdk/UserList';
import MessageWindow from '../components/chat_sdk/MessageWindow';
import { useDispatch, useSelector } from 'react-redux';
import ChatLoader from '../components/chat/ChatLoader';
import { useChat } from '../context/chat.context';
import {
  appLoadingFinished,
  incrementUnreadCount,
  storeGroupList,
  storeHCPatientIds,
  updateGroupList,
} from '../store/message/actions';
import { Helmet } from 'react-helmet';
import { getUrlParams } from '../helpers/common';
import { getPatientIdsListApi } from '../api';
import store from '../store';

const ChatPortal = () => {
  const dispatch = useDispatch();
  const { Chat, updateUserDetails } = useChat();
  const { loading: appLoading } = useSelector((s) => s.message);
  const urlParams = getUrlParams();

  const setupChannelListener = async (channelId) => {
    const channel = await Chat.getChannel(channelId);
    channel.connect((message) => handleNewMessage(channel, message));
  };

  const handleNewMessage = (channel, message) => {
    updateUserDetails();

    const selectedChat = store.getState()?.message?.selectedChat;
    if (message?.channelId !== selectedChat?.channel?.id) {
      dispatch(incrementUnreadCount(channel.id));
      new Notification(channel?.name, {
        body: message?.content?.text || 'Shared a file.',
      });
    }
    dispatch(updateGroupList(channel.id, message));
  };

  const fetchGroupList = async () => {
    try {
      const fetchAllMemberships = async () => {
        const memberships = [];
        let fetchedCount = 0;
        let nextPage = '';

        const firstRes = await Chat.currentUser.getMemberships({
          limit: 100,
          sort: { updated: 'desc' },
        });

        const total = firstRes?.total ?? 0;
        Array.prototype.push.apply(memberships, firstRes?.memberships ?? []);
        fetchedCount += firstRes?.memberships?.length ?? 0;
        nextPage = firstRes?.page?.next ?? '';

        while (fetchedCount < total && nextPage) {
          const res = await Chat.currentUser.getMemberships({
            limit: 100,
            sort: { updated: 'desc' },
            page: { next: nextPage },
          });

          Array.prototype.push.apply(memberships, res?.memberships ?? []);
          fetchedCount += res?.memberships?.length ?? 0;
          nextPage = res?.page?.next ?? '';
        }

        return memberships;
      };

      const memberships = await fetchAllMemberships();
      const totalCount = memberships.length;

      const unreadMemberships = await Chat.getUnreadMessagesCounts();

      const unreadCounts = {};
      unreadMemberships.forEach((m) => {
        unreadCounts[m.channel.id] = m.count;
      });

      const updatedMemberships = memberships.map((membership) => {
        const unreadCount = unreadCounts[membership.channel.id] || 0;
        return {
          ...membership,
          channel_meta_info: {
            unread_count: unreadCount,
          },
        };
      });

      const sortedMemberships = updatedMemberships.sort((a, b) => {
        const isAUnread = a.channel_meta_info.unread_count > 0;
        const isBUnread = b.channel_meta_info.unread_count > 0;

        if (isAUnread && !isBUnread) {
          return -1;
        } else if (!isAUnread && isBUnread) {
          return 1;
        }

        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });

      dispatch(
        storeGroupList({
          totalCount,
          page: { next: '', prev: '' },
          groupsList: sortedMemberships,
        }),
      );

      const setupListenersForChannels = async (memberships) => {
        await Promise.all(
          memberships.map(({ channel: { id } }) => setupChannelListener(id)),
        );
      };

      await setupListenersForChannels(updatedMemberships);

      return true;
    } catch (error) {
      console.error('[FETCH GROUPS ERROR]:', error);
      return false;
    }
  };

  const fetchHealthCoachPatientIds = async () => {
    try {
      const query_params = {
        health_coach_id: urlParams?.sender,
      };
      const { data } = await getPatientIdsListApi(query_params);
      dispatch(storeHCPatientIds(data));
      return true;
    } catch (error) {
      console.log('[FETCH PATIENT ID ERROR]: ', error);
    }
  };

  const initiateChatPortal = async () => {
    try {
      const [groupList, healthCoachPatientIds] = await Promise.all([
        fetchGroupList(),
        fetchHealthCoachPatientIds(),
      ]);
      if (groupList && healthCoachPatientIds) {
        dispatch(appLoadingFinished());
      }
    } catch (error) {
      console.log('[ERROR INITIATING CHAT PORTAL]: ', error);
    }
  };

  useEffect(() => {
    if (Chat) {
      initiateChatPortal();
    }
  }, [Chat]);

  return (
    <div className={'flex flex-row'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{urlParams?.sender_name}</title>
      </Helmet>
      {appLoading && <ChatLoader />}
      <UserList />
      <MessageWindow />
    </div>
  );
};

export default ChatPortal;
