import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearUnreadMessageCount,
  selectChatAction,
} from '../../store/message/actions';
import { getUrlParams, haveCommonElements } from '../../helpers/common';

const UserListItem = ({ userlistItem }) => {
  const dispatch = useDispatch();
  const urlParams = getUrlParams();
  const { selectedChat, patientIds, groupsList } = useSelector(
    (s) => s.message,
  );

  const currentChatItem = groupsList?.find(
    (g) => g?.channel?.id === userlistItem?.channel?.id,
  );

  const isChief = urlParams?.hc_type === 'chief';

  const [isChatEnabled, setIsChatEnabled] = useState(false);

  const onSelectChat = (channel) => {
    dispatch(clearUnreadMessageCount(channel?.id));
    dispatch(
      selectChatAction({
        channel,
      }),
    );
  };

  const checkUserMembership = async () => {
    try {
      const { members } = await userlistItem?.channel?.getMembers();
      const memberIds = members.map((m) => m?.user?.id);
      setIsChatEnabled(haveCommonElements(memberIds, patientIds));
    } catch (error) {
      console.log('[CHECK USER MEMBERSHIP ERROR]: ', error);
    }
  };

  useEffect(() => {
    checkUserMembership();
  }, [patientIds]);

  return (
    <li
      className={`w-full p-2 flex flex-row items-center gap-2 cursor-pointer border-b-[0.1px] border-gray-200 ${selectedChat?.channel?.id === userlistItem?.channel?.id && 'bg-purple-200'}`}
      onClick={() => onSelectChat(userlistItem?.channel)}
    >
      <div className="h-10 w-10 min-h-[40px] min-w-[40px] flex items-center justify-center bg-gray-200 rounded-full relative">
        {!isChatEnabled && !isChief && (
          <div className="w-12 h-[4px] bg-gray-500 border-[1px] border-gray-200 absolute z-10 -rotate-45" />
        )}
        {userlistItem?.channel?.type === 'direct' ? (
          <i className="fa-solid fa-user text-xl text-gray-500"></i>
        ) : (
          <i className="fa-solid fa-user-group text-xl text-gray-500"></i>
        )}
      </div>
      <div className="w-full h-[48px]">
        <div className="flex item-center justify-between gap-[8px]">
          <div
            className={`text-[16px] ${isChatEnabled ? 'text-black' : 'text-gray-500'}`}
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {userlistItem?.channel?.type === 'direct'
              ? userlistItem?.channel?.custom?.patientName
              : userlistItem?.channel?.name}
          </div>
          {currentChatItem?.channel_meta_info?.unread_count > 0 && (
            <div className="w-[28px] h-[28px] min-w-[28px] min-h-[28px] rounded-full bg-green-700 flex items-center justify-center text-white">
              <p>{currentChatItem?.channel_meta_info?.unread_count}</p>
            </div>
          )}
        </div>
        {!isChatEnabled && !isChief && (
          <div className="text-gray-500 text-[10px]">
            Not a part of this group anymore.
          </div>
        )}
      </div>
    </li>
  );
};

export default UserListItem;
