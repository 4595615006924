export const STORE_GROUP_LIST = 'STORE_GROUP_LIST';
export const STORE_HC_PATIENT_IDS = 'STORE_HC_PATIENT_IDS';
export const SELECT_CHAT = 'SELECT_CHAT';
export const STORE_HISTORY = 'STORE_HISTORY';
export const FETCH_HISTORY = 'FETCH_HISTORY';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const INCREMENT_UNREAD_COUNT = 'INCREMENT_UNREAD_COUNT';
export const CLEAR_UNREAD_MESSAGE = 'CLEAR_UNREAD_MESSAGE';
export const APP_LOADING_FINISHED = 'APP_LOADING_FINISHED';
export const STORE_MESSAGE_SCROLL_ID = 'STORE_MESSAGE_SCROLL_ID';
export const CLEAR_MESSAGE_SCROLL_ID = 'CLEAR_MESSAGE_SCROLL_ID';
